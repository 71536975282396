:root {
  --ui-accent-color-primary: #fca311;
  --ui-accent-color-secondary: #eb9812; 
  --ui-accent-color-tertiary: #af6e06;
  --ui-panel-bg-color-0: rgba(15, 15, 15, 0.7); 
  --ui-panel-bg-color-1: rgba(15, 15, 15, 0.8); 
  --ui-panel-bg-color-2: rgba(15, 15, 15, 0.9);
  --ui-panel-bg-color-3: rgba(15, 15, 15, 1); 
  --ui-card-bg-color-1: rgba(40, 40, 40, 0.5);
  --ui-card-bg-color-2: rgba(60, 60, 60, 0.5);
  --ui-card-bg-color-3: rgba(80, 80, 80, 0.5);
  --ui-std-box-shadow-1: 0px 0px 20px rgba(15, 15, 15, 0.25); 
  --ui-std-box-shadow-2: 0px 0px 20px rgba(15, 15, 15, 0.15); 
  --ui-active-box-shadow-2: 0px 0px 20px rgba(39, 136, 221, 0.15); 
  --ui-active-color: rgb(39, 136, 221);
  --ui-active-color-2: rgb(28, 122, 204);
}

@import "~react-vis/dist/style";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img, h1, h2, h3, h4, h5, h6, p, label, div, span {
  user-select: none;
  -webkit-user-select: none;
}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

a,
.action-line {
  text-decoration: none;
  color: var(--ui-accent-color-secondary);
  transition-duration: 100ms;
  transition-timing-function: ease;
  cursor: pointer;
}

a:hover,
.action-line:hover {
  color: var(--ui-accent-color-tertiary);
}

.link {
  text-decoration: underline;
  color: rgb(180, 180, 180);
  transition-duration: 100ms;
  transition-timing-function: ease;
  cursor: pointer;
}

.link:hover {
  color: rgb(220, 220, 220);
}

.action-link {
  color: var(--ui-active-color);
  transition-duration: 100ms;
  transition-timing-function: ease;
  cursor: pointer;
}

.action-link:hover {
  color: var(--ui-active-color-2);
}

button {
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  padding-right: 25px;
  padding-left: 25px;
  max-width: 200px;
}

button:disabled {
  border-radius: 5px;
  background-color: rgb(40, 40, 40);
  color: rgb(200, 200, 200);
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: rgb(40, 40, 40);
  color: rgb(200, 200, 200);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus { 
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 50px #1B1D1F inset;
  -webkit-box-shadow: 0 0 0 50px #1B1D1F inset;
  -webkit-text-fill-color: rgb(250, 250, 250) !important;
}

input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=tel],
input[type=number],
input[type=url] {
  width: 100%;
  height: 36px;
  padding: 6px 12px 6px 12px;
  margin-top: 8px;
  outline: none;
  border: rgb(60, 60, 60) 1px solid;
  border-radius: 4px;
  color: rgb(250, 250, 250);
  background-color: var(--ui-card-bg-color-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=search]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder,
input[type=url]::placeholder,
textarea::placeholder {
  color: rgb(120, 120, 120);
}

input[type=text]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus {
  border: rgb(160, 160, 160) 1px solid;
}

input[type=text]:focus::placeholder,
input[type=password]:focus::placeholder,
input[type=search]:focus::placeholder,
input[type=email]:focus::placeholder,
input[type=tel]:focus::placeholder,
input[type=number]:focus::placeholder,
textarea:focus::placeholder {
  color: rgb(160, 160, 160);
}

input:disabled,
textarea:disabled {
  background-color: var(--ui-panel-bg-color-3);
  cursor: not-allowed;
}

input[type=file] {
  display: none;
}

input[type=number] {
  -webkit-appearance: none;
  height: 42px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=radio] {
  margin-right: 4px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 12px;
  margin-top: 8px;
  outline: none;
  border: rgb(60, 60, 60) 1px solid;
  border-radius: 4px;
  color: rgb(250, 250, 250);
  background-color: var(--ui-card-bg-color-1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  resize: vertical;
}

textarea::-webkit-scrollbar-corner {
  border-right: unset;
  border-bottom: unset;
}

label {
  color: rgb(250, 250, 250);
  font-size: 12px;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track:vertical {
  border-right: #3d3d3d7e 6px solid;
}

::-webkit-scrollbar-thumb:vertical {
  background-clip: padding-box;
  border-right: rgb(100, 100, 100) 6px solid;
}

::-webkit-scrollbar-track:horizontal {
  border-bottom: #3d3d3d7e 6px solid;
}

::-webkit-scrollbar-thumb:horizontal {
  background-clip: padding-box;
  border-bottom: rgb(100, 100, 100) 6px solid;
}

::-webkit-scrollbar-corner {
  background: inherit;
  border-right: rgb(100, 100, 100) 6px solid;
  border-bottom: rgb(100, 100, 100) 6px solid;
}

::-webkit-scrollbar-thumb:hover {
  border-color: rgb(120, 120, 120);
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.ghost-scroll::-webkit-scrollbar {
  display: none;
  position: absolute;
  bottom: 0;
}

.ghost-scroll:hover::-webkit-scrollbar {
  display: unset;
}

table {
  position: relative;
  width: 100%;
  color: rgb(180, 180, 180);
  font-size: 13px;
  overflow-x: auto;
}

tr {
  background-color: var(--ui-card-bg-color-2);
}

thead > tr, tr:nth-child(even) {
  background-color: var(--ui-card-bg-color-1);
}

thead > tr {
  color: rgb(200, 200, 200);
  font-weight: 600;
  text-transform: capitalize;
}

td, th {
  padding: 8px 10px;
}

h6.section-title {
  color: rgb(180, 180, 180);
  font-weight: 500;
}

.edit {
  cursor: auto;
  padding: 5px;
  margin-top: 10px;
  font-size: 11px;
  border-collapse: collapse;
}

.edit thead tr td {
  background-color: rgb(0, 0, 0);
  color: white;
}

.edit td {
  border: rgb(165, 165, 165) 0.5px solid;
  width: 135px;
  max-width: 135px;
  height: 20px;
  padding: 5px;
  text-transform: capitalize;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.card {
  position: relative;
  width: clamp(250px, fit-content, 400px);
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(2, 12, 22);
  color: rgb(180, 180, 180);
  overflow-x: hidden;
}

.card h5 {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.card h5 .icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.card p {
  position: relative;
  width: fit-content;
  font-size: 13px;
}

.card .btn-group > button {
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*Animations*/

@keyframes appear {
  to {
    opacity: 1;
  }
}

@keyframes rise {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes riseMargin {
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}

@keyframes leftAppear {
  from {
    transform: translateX(-80px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rightAppear {
  from {
    transform: translateX(80px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}

@keyframes collapseRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    width: 0;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(120, 120, 120);
}

.square {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  background-color: rgb(40, 40, 40);
  transition: background-color ease 200ms;
}

.fit-btn {
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
}

.action-btn {
  height: 36px;
  border-radius: 5px;
  background-color: var(--ui-accent-color-primary);
  color: rgb(250, 250, 250);
  box-shadow: var(--ui-std-box-shadow-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.action-btn:hover {
  background-color: var(--ui-accent-color-secondary);
  box-shadow: var(--ui-std-box-shadow-2);
}

.loader-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-btn .loader-frame {
  width: fit-content;
  height: fit-content;
  margin: 0px 8px 2px 0;
}

.secondary-btn {
  height: 36px;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  color: rgb(41, 41, 41);
  box-shadow: var(--ui-std-box-shadow-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.secondary-btn:hover {
  background-color: rgb(218, 218, 218);
  box-shadow: var(--ui-std-box-shadow-2);
}

.tertiary-btn {
  height: 36px;
  border-radius: 5px;
  border: rgb(100, 100, 100) 1px solid;
  color: rgb(206, 206, 206);
  background-color: transparent;
  box-shadow: var(--ui-std-box-shadow-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.tertiary-btn:hover {
  color: rgb(235, 235, 235);
  border-color: rgb(140, 140, 140);
  box-shadow: var(--ui-std-box-shadow-2);
}

.delete-btn {
  height: 36px;
  border-radius: 5px;
  background-color: rgb(224, 28, 28);
  color: rgb(250, 250, 250);
  box-shadow: var(--ui-std-box-shadow-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.delete-btn:hover {
  background-color: rgb(194, 5, 5);
  box-shadow: var(--ui-std-box-shadow-2);
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-btn .icon {
  margin: 0 7px 2px -4px;
}

.border-btn {
  height: 36px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 5px;
  color: rgb(180, 180, 180);
  background-color: rgba(40, 40, 40, 0.5);
  border: rgb(60, 60, 60) 1px solid;
  box-shadow: var(--ui-std-box-shadow-1);
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.border-btn-active {
  border-color: var(--ui-active-color-2);
}

.border-btn:hover {
  background-color: rgba(50, 50, 50, 0.5);
}

.blank-btn {
  height: 36px;
  border-radius: 5px;
  color: var(--ui-active-color);
  background-color: transparent;
}

.blank-btn:hover {
  color: var(--ui-active-color-2);
}

header {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(10px);
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  z-index: 100;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(15, 15, 15, 0.15);
  backdrop-filter: blur(10px);
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer > .copyright {
  width: 33.33%;
  min-width: 150px;
  color: rgb(220, 220, 220);
  font-size: 13px;
  white-space: nowrap;
}

footer > .motto {
  width: 33.33%;
  min-width: 150px;
  color: rgb(220, 220, 220);
  font-size: 13px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: appear 600ms ease 500ms forwards;
}

footer > .motto > .icon {
  width: 22px;
  height: auto;
  margin: 2px 0 0 6px;
}

footer > .social {
  width: 33.33%;
  min-width: 150px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social img {
  width: 20px;
  height: auto;
  cursor: pointer;
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.small-logo {
  height: clamp(30px, 90%, 50px);
  padding: 4px;
  width: auto;
  cursor: pointer;
}

.flex-search {
  position: relative;
  height: 100%;
  margin: 5px;
  padding-top: 3px;
  margin-left: 20px;
  z-index: 2;
}

.flex-search input {
  height: 92%;
  width: 280px;
  margin: 0;
  padding: 6px 32px 6px 32px;
  outline: none;
  color: rgb(250, 250, 250);
}

.flex-search .icon {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 12px;
  height: 12px;
}

.flex-search .flat-hint {
  position: absolute;
  right: 8px;
  top: 10px;
  padding-bottom: 2px;
}

.flex-search input:focus {
  width: 340px;
}

.search-results {
  width: 100%;
  max-height: 80vh;
  background-color: var(--ui-panel-bg-color-2);
  margin-top: -1px;
  z-index: 10;
  overflow-y: auto;
  transition: all ease 150ms;
}

.search-result {
  padding: 10px 15px;
  cursor: pointer;
}

.search-result:hover {
  background-color: var(--ui-active-color-2);
}

.text-content {
  position: relative;
}

.text-content h4 {
  width: 260px;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(220, 220, 220);
}

.text-content p {
  width: 260px;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(200, 200, 200);
}

.profile-roundel {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.roundel {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  overflow: hidden;
}

.small-roundel {
  position: relative;
  width: 27px;
  height: 27px;
  min-width: 27px;
  min-height: 27px;
  border-radius: 50%;
  overflow: hidden;
}

.smaller-roundel {
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-roundel img, 
.smaller-roundel img, 
.small-roundel img, 
.roundel img {
  width: 100%;
  height: 100%;
}

.tab-menu {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 30px;
}

.tab-menu a {
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  color: rgb(220, 220, 220);
  font-size: 13.5px;
  text-transform: capitalize;
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.tab-menu a:hover {
  color: rgb(160, 160, 160);
}

.avatar-menu {
  position: relative;
  height: 100%;
  width: 45px;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 100;
}

.default-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-avatar > p {
  width: 100%;
  line-height: 100%;
  vertical-align: middle;
  text-align: center;
  color: #fafafa;
}

.avatar-menu > .small-roundel > .default-avatar {
  font-size: 12px;
}

.avatar-menu .status-indicator {
  position: absolute;
  bottom: 5px;
  right: 18px;
  margin: 0;
}

.large-icon {
  display: block;
  width: 24px;
  height: 24px;
}

.icon {
  width: 15px;
  height: 15px;
}

.small-icon {
  width: 12px;
  height: 12px;
}

.avatar-menu-open > .small-icon {
  transform: rotate(180deg);
  transition: all 300ms ease;
}

.hamburger {
  z-index: 51;
  display: none;
}

.hamburger-btn {
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.ham-top, .ham-cheese, .ham-bottom {
  width: calc(100% - 5.25px);
  height: 1.2px;
  background-color: rgb(160, 160, 160);
  transition: all 300ms ease;
}

.hamburger-btn:hover > .ham-top,
.hamburger-btn:hover > .ham-cheese,
.hamburger-btn:hover > .ham-bottom {
  background-color: rgb(200, 200, 200);
}

.ham-top {
  position: absolute;
  top: 5px;
  left: 5px;
  transform-origin: 0 50%;
}

.ham-cheese {
  position: absolute;
  top: 10px;
  left: 5px;
}

.ham-bottom {
  position: absolute;
  top: 15px;
  left: 5px;
  transform-origin: 0 50%;
}

.hamburger-close > .ham-cheese {
  display: none;
}

.hamburger-close > .ham-top {
  transform: rotate(45deg);
}

.hamburger-close > .ham-bottom {
  transform: rotate(-45deg);
}

.hamburger-menu {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 100%;
  background-color: var(--ui-panel-bg-color-2);
  backdrop-filter: blur(10px);
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 51;
}

.hamburger-menu > .flex-search {
  height: max-content;
}

.hamburger-menu > .flex-search > input {
  height: 36px;
}

.hamburger-menu > .flex-search > .search-results {
  position: relative;
}

.hamburger-menu > .vertical-menu {
  position: relative;
  width: 280px;
  margin-top: 30px;
}

.vertical-menu .vertical-menu-item {
  position: relative;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: rgb(220, 220, 220);
  font-size: 13.5px;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
}

.vertical-menu .vertical-menu-item:hover {
  background-color: rgba(150, 150, 150, 0.2);
}

.vertical-menu .vertical-menu-item > a {
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.vertical-menu .vertical-menu-item > a:hover {
  color: rgb(160, 160, 160);
}

/*Drop down*/

.drop-menu {
  position: relative;
  height: 100%;
  width: max-content;
  margin-left: auto;
  margin-right: 0px;
  padding-right: 10px;
  font-size: 13.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.drop-menu p {
  color: rgb(220, 220, 220);
  margin-right: 6px;
  text-transform: capitalize;
}

.drop-menu-disabled {
  cursor: default;
}

.drop-menu-disabled .small-icon {
  display: none;
}

.drop-menu-disabled p {
  color: rgb(160, 160, 160);
}

.drop-down {
  position: absolute;
  right: 0;
  background-color: var(--ui-panel-bg-color-1);
  box-shadow: var(--ui-std-box-shadow-1);
  z-index: 100;
}

.drop-down > .link {
  text-decoration: none;
}

.menu-item {
  position: relative;
  padding: 10px 15px;
  color: rgb(220, 220, 220);
  display: flex;
  align-items: center;
  transition-duration: 100ms;
  transition-timing-function: ease;
  z-index: 10;
}

.menu-item:not(.current-menu-item):hover {
  background-color: var(--ui-card-bg-color-2);
}

.current-menu-item {
  background-color: var(--ui-active-color);
  color: rgb(220, 220, 220);
}

.menu-item p {
  position: relative;
  font-size: 13.5px;
  text-transform: capitalize;
  margin-left: 10px;
  white-space: nowrap;
}

/*Select*/

.select {
  position: relative;
  width: 100%;
  margin: 10px 0;
  font-size: 13.5px;
}

.select > .current-menu-item {
  position: relative;
  height: 36px;
  width: 100%;
  padding: 0 10px;
  font-size: 13.5px;
  border: rgb(60, 60, 60) 1px solid;
  border-radius: 4px;
  color: rgb(250, 250, 250);
  background-color: var(--ui-card-bg-color-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;
}

.select > .current-menu-item > .icon {
  margin-right: 8px;
}

.select > .current-menu-item > .small-icon {
  margin: 0 0 0 6px;
}

.select > .current-menu-item > .pointer {
  transform: rotate(-90deg);
}

.select > .disabled-select {
  background-color: transparent;
  color: rgb(180, 180, 180);
  cursor: default;
}

.select-open > .current-menu-item {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select-open > .current-menu-item:not(.disabled-select) > .chevron {
  transform: rotate(180deg);
}

.select > .disabled-select > .chevron {
  display: none;
}

.select-picker > .current-menu-item {
  border-radius: 4px;
  border-color: var(--ui-active-color-2);
}

.select > .drop-down {
  position: absolute;
  top: 36px;
  left: 0;
  height: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  transition: all 200ms ease;
}

.select-open > .drop-down {
  height: initial;
  background-color: rgb(28, 28, 28);
  border: rgb(60, 60, 60) 1px solid;
  border-top: transparent 1px solid;
  transition: all 200ms ease;
}

.select > .drop-down > .menu-item {
  height: 36px;
  padding: 5px 0;
  box-shadow: none;
  cursor: pointer;
  opacity: 0;
  white-space: nowrap;
}

.select-open > .drop-down > .menu-item {
  opacity: 1;
}

.select-open > .drop-down > .menu-item:not(.current-menu-item) {
  padding-left: 10px;
}

.select > .drop-down > .menu-item > .small-icon {
  transition: all ease 200ms;
}

.select p {
  color: rgb(220, 220, 220);
  margin: 0;
  text-transform: capitalize;
}

/*Multiselect*/

.multiselect.input-container {
  min-width: 150px;
  width: fit-content;
  max-width: 100%;
}

.multiselect label {
  text-transform: capitalize;
}

.multiselect > div {
  margin-top: 10px;
}

.multiselect > div > .checkbox {
  margin-bottom: 5px;
}

/*Crumb Menu*/

.crumb-menu {
  position: relative;
  width: 10px;
}

.crumbs {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.crumbs > div {
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background-color: rgb(200, 200, 200);
  margin: 3px auto;
}

.crumbs-open > div {
  background-color: var(--ui-active-color-2);
}

.crumb-menu > .drop-down {
  max-height: 200px;
  overflow: auto;
}

/*Modals*/

.error-modal > .body > p,
.error-modal > .body > h4 {
  font-size: 13.5px;
  margin: 10px 0;
}

/*Picker*/

.picker {
  width: fit-content;
}

.picker-menu-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
}

.picker .select-open > .current-menu-item,
.picker-menu .select-open > .current-menu-item {
  border-radius: 4px;
}

@keyframes expand {
  to {
    max-height: 1000px;
  }
}

.picker-menu {
  position: relative;
  width: max-content;
  height: max-content;
  max-width: 100%;
  padding-top: 40px;
  border-radius: 4px;
  background-color: rgba(20, 20, 20, 0.8);
  border: rgb(40, 40, 40) 1px solid;
  max-height: 0;
  overflow: hidden;
  animation: expand 300ms ease-in forwards;
}

.picker-menu > .btn-group {
  margin-top: 10px;
  padding: 10px;
  border-top: rgb(40, 40, 40) 1px solid;
}

.picker-menu > .btn-group > button {
  height: 32px;
}

.picker-menu .select {
  max-width: fit-content;
}

.picker-menu .crumb-menu {
  position: absolute;
  right: 5px;
  display: none;
  margin: 0 3px;
}

.picker-menu > .body {
  display: flex;
}

.picker-menu > .body > .vertical-menu {
  padding: 10px 0 0;
  background-color: unset;
  flex: 1;
}

.picker-menu > .body > .vertical-menu > .vertical-menu-item {
  background-color: unset;
  justify-content: flex-start;
  padding: 0 10px;
}

.picker-menu > .body > .vertical-menu > .selected-menu-item {
  background-color: var(--ui-active-color-2);
}

.picker-menu > .body > .vertical-menu > .vertical-menu-item:not(.selected-menu-item):hover {
  background-color: var(--ui-card-bg-color-1);
}

.picker-menu > .body > .block-menu {
  position: relative;
  flex: 3;
  width: 340px;
  max-width: 340px;
  height: 100%;
}

.picker-menu > .body > .block-menu > .flex-container > .select {
  margin: 10px;
}

.picker-menu > .body > .block-menu > .calendar > .day-picker {
  width: calc(100% - 10px);
  margin: 10px 0 10px 5px;
}

.picker-menu > .body > .block-menu > .calendar > .time-picker {
  margin-top: 20px;
}

.calendar .select {
  width: fit-content;
}

.calendar .timestamp {
  color: rgb(180, 180, 180);
  font-size: 13px;
  width: fit-content;
  margin-left: 5px;
}

/*Time Presets*/

.time-presets {
  position: relative;
  height: fit-content;
  padding: 5px;
}

.time-presets > h6 {
  padding: 0 5px;
  margin-bottom: 10px;
}

.time-presets > .body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.rect-block {
  position: relative;
  width: 85px;
  height: 85px;
  margin: 5px;
  border-radius: 4px;
  border: rgb(40, 40, 40) 2px solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.rect-block:not(.selected-outline):hover {
  filter: brightness(140%);
}

.rect-block.selected-outline {
  border: var(--ui-active-color-2) 2px solid;
  outline: none;
}

.rect-block h5 {
  font-size: 10px;
  font-weight: 500;
  color: rgb(160, 160, 160);
  text-transform: capitalize;
  margin: 5px 0;
}

.rect-block p {
  font-size: 9px;
  color: rgb(180, 180, 180);
}

.rect-block .icon {
  width: 20px;
  height: 20px;
  filter: opacity(0.8);
}

/*Month Picker*/

.month-picker {
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 5px;
}

.month-picker > .month {
  padding: 10px;
  color: rgb(160, 160, 160);
  font-size: 12px;
  background-color: var(--ui-card-bg-color-2);
  cursor: pointer;
}

.month.selected-tile {
  background-color: var(--ui-active-color-2);
  color: rgb(200, 200, 200);
}

.month-picker > .month:not(.selected-tile):hover {
  background-color: var(--ui-card-bg-color-3);
}

/*Day Picker*/

.day-picker {
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  text-align: center;
}

.day-label {
  font-size: 11px;
  font-weight: 400;
  color: rgb(160, 160, 160);
  background-color: transparent;
  margin-bottom: 5px;
}

.day {
  height: 30px;
  font-size: 12px;
  color: rgb(160, 160, 160);
  background-color: var(--ui-card-bg-color-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.day:not(.selected-tile):hover {
  background-color: var(--ui-card-bg-color-3);
}

.day.disabled {
  cursor: default;
}

.day.selected-tile {
  background-color: var(--ui-active-color-2);
  color: rgb(200, 200, 200);
}

.current-month {
  color: rgb(180, 180, 180);
  background-color: var(--ui-card-bg-color-2);
}

/*Time Picker*/

.time-picker {
  position: relative;
  width: calc(100% - 10px);
  margin: 0 0 10px 5px;
  align-items: center;
}

.time-picker > .number-input-container {
  position: relative;
  min-width: 0;
  width: fit-content;
  height: 32px;
  margin: 0 10px 0 0;
  padding: 0;
}

.time-picker > .number-input-container > .number-input,
.time-picker > .number-input-container > .number-input input {
  width: 100%;
  height: 100%;
  margin: 0;
}

.time-picker > .number-input-container > .number-input input {
  padding-right: 10px;
}

.time-picker > .icon {
  margin-right: 10px;
}

/*Calendar*/

.calendar {
  position: relative;
  margin: 5px;
}

.calendar .select {
  margin: 5px;
  max-width: none;
}

/*Option Area*/

.option-area {
  position: relative;
  margin: 10px 0 20px;
  padding: 5px;
  border-radius: 4px;
  border: rgb(60, 60, 60) 1px solid;
}

.option-area .badge {
  position: relative;
  padding: 4px 8px;
  margin: 5px;
  background-color: rgb(40, 40, 40);
  font-weight: 400;
}

.option-area .active-badge {
  background-color: var(--ui-active-color-2);
  color: rgb(235, 235, 235);
  font-weight: 400;
}

/*Form Progress*/

.form-step {
  position: relative;
  width: 100%;
  padding: 10px;
  color: rgb(100, 100, 100);
  transition: all 400ms ease;
}

.form-step > h5 {
  font-size: 10px;
}

.form-step > p {
  font-size: 16px;
}

.form-step:hover {
  background-color: rgba(65, 65, 65, 0.25);
}

.form-step-active {
  color: rgb(180, 180, 180);
}

/*Login Form*/

.login-form {
  position: relative;
  width: clamp(300px, 400px, 95%);
  height: max-content;
  padding: 20px 20px 25px;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(20px);
  box-shadow: var(--ui-std-box-shadow-1);
}

.login-form .btn-group {
  margin-top: 30px;
}

.form-title {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title img {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.form-title > h3 {
  color: rgb(250, 250, 250);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.form-error {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 11.5px;
  font-weight: 600;
  color: rgb(224, 28, 28);
}

.form-invite {
  position: relative;
  text-align: right;
  font-size: 12px;
}

/*Signup form*/

.signup-form {
  position: relative;
  width: clamp(300px, 400px, 95%);
  height: max-content;
  padding: 20px 20px 25px;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(20px);
  box-shadow: var(--ui-std-box-shadow-1);
  transition: height 600ms ease;
}

.signup-form .btn-group {
  margin-top: 30px;
}

.signup-form > .loader-frame,
.login-form > .loader-frame {
  height: 180px;
  margin: 30px 0 0;
}

.signup-form > .loader-frame > .loader-text,
.login-form > .loader-frame > .loader-text {
  margin-top: 0;
}

.signup-form > p {
  font-size: 13px;
  color: rgb(160, 160, 160);
}

.input-group {
  position: relative;
  width: 100%;
}

.input-group > .checkbox {
  margin-bottom: 5px;
}

.form-instruction {
  width: 100%;
  margin: 15px 0 30px;
  padding: 0 15px;
  color: rgb(180, 180, 180);
  font-size: 12px;
  text-align: center;  
  text-transform: uppercase;
}

.image-upload {
  position: relative;
  width: fit-content;
}

.image-upload > label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms ease;
}

.image-upload > label > .icon {
  width: 24px;
  height: 24px;
  margin: 10px;
  opacity: 0;
}

.image-upload > label:hover {
  background-color: rgba(15, 15, 15, 0.75);
}

.image-upload > label:hover > .icon {
  opacity: 1;
}

/*Title*/

.plot-title {
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 15px;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  z-index: 20;
}

.title {
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 15px;
  display: flex;
  align-items: center;
  z-index: 20;
  background-color: rgb(6, 22, 37);
  border-bottom: rgb(60, 60, 60) 1px solid;
}

.plot-title h4, .title h4 {
  position: relative;
  color: rgb(250, 250, 250);
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.plot-title h4 .icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  margin-bottom: 2px;
}

.body {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.plot-title > .error {
  margin-right: 15px;
  margin-left: auto;
  display: inline-block;
  color: rgb(224, 28, 28);
  font-size: 12.5px;
  font-weight: 500;
}

.plot-title > .loader-frame,
.title > .loader-frame {
  width: fit-content;
  margin-left: 10px;
}

.title .status-indicator {
  margin-left: 6px;
}

/*Toggle switch*/
.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch-label {
  font-size: 11px;
  margin: 6px;
  color: rgb(160, 160, 160);
}

.switch-label-active {
  color: rgb(200, 200, 200);
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: var(--ui-card-bg-color-3);
  transition: all 300ms ease;
  cursor: pointer;
}

.switch-handle::before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: rgb(218, 218, 218);
  transition: all 300ms ease;
}

.toggle-switch-disabled .switch-handle::before {
  background-color: rgb(160, 160, 160);
}

.toggle-switch-disabled .switch-handle {
  cursor: not-allowed;
}

.switch input:checked + .switch-handle {
  background-color: var(--ui-active-color);
}

.switch input:checked + .switch-handle::before {
  transform: translateX(14px);
}

/*Range Slider*/

.range-slider-container {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10px 30px;
}

.range-slider {
  position: relative;
  width: 100%;
  height: 10px;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  z-index: 2;
  height: 10px;
  width: 100%;
  pointer-events: none;
  opacity: 0;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border: 0 none;
  -webkit-appearance: none;
  pointer-events: all;
  cursor: pointer;
}

.slider {
  position: relative;
  z-index: 1;
  height: 10px;
  margin: 0 8px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 4px;
  right: 0;
  bottom: 4px;
  background-color: rgb(49, 49, 49);
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 30%;
  right: 30%;
  top: 4px;
  bottom: 4px;
  border-radius: 5px;
  background-color: var(--ui-active-color-2);
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(180, 180, 180);
}

.slider > .thumb.left {
  left: 30%;
  transform: translate(-50%, calc(-50% + 5px));
}

.slider > .thumb.right {
  right: 30%;
  transform: translate(50%, calc(-50% + 5px));
}

.range-slider-ticks {
  position: relative;
  width: calc(100% - 16px);
  height: 5px;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 8px;
}

.range-slider-ticks > div {
  position: absolute;
  width: 2px;
  height: 100%;
  transform: translateX(-50%);
  border-right: rgb(49, 49, 49) 2px solid;
}

.range-slider-labels {
  position: relative;
  width: calc(100% - 16px);
  height: 20px;
  margin-top: 10px;
  color: rgb(120, 120, 120);
  font-size: 12px;
  margin-left: 8px;
}

.range-slider-labels p {
  position: absolute;
  transform: translateX(-50%);
  max-width: 50px;
  cursor: pointer;
}

.range-slider-labels p:hover {
  color: rgb(160, 160, 160);
}

.range-slider-label-active {
  color: rgb(180, 180, 180);
}

/*Line Progress*/

.custom-progress-container {
  background-color: rgb(6, 22, 37);
  padding: 0 10px;
  border: transparent 2px solid;
  transition: all 400ms ease;
}

.line-progress-container {
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  color: rgb(220, 220, 220);
  font-size: 13px;
  text-transform: capitalize;
}

.line-progress-container p {
  width: clamp(10%, 50px, 20%);
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.line-progress-bar {
  position: relative;
  height: 6px;
  width: 100%;
  margin: 0 10px;
  background-color: rgb(40, 40, 40);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.line-progress-thumb {
  position: absolute;
  left: 1px;
  height: 100%;
  background-color: var(--ui-accent-color-secondary);
  border-radius: 3px;
  transition-delay: 300ms;
  transition-duration: 600ms;
  transition-timing-function: ease;
}

/*Number input*/
.number-input {
  position: relative;
  width: 90px;
}

.number-input > input {
  display: block;
  padding-right: 30px;
}

.number-input-btn-up, .number-input-btn-down {
  position: absolute;
  right: 0;
  top: 0;
  width: 22px;
  height: 50%;
  background-color: var(--ui-card-bg-color-2);
  color: rgb(160, 160, 160);
  font-size: 11px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.number-input-btn-down {
  border-top: rgb(100, 100, 100) 1px solid;
  top: unset;
  bottom: 0;
}

.number-input-btn-up:hover, .number-input-btn-down:hover {
  background-color: var(--ui-card-bg-color-3);
}

/*Checkbox*/

.checkbox {
  position: relative;
  padding: 0;
}

.checkbox > input[type=checkbox] {
  margin: 1px 8px 0 0;
  cursor: pointer;
}

.checkbox > label {
  display: inline-block;
  cursor: pointer;
}

.checkbox > :not(input[type=checkbox]:checked) + label {
  color: rgb(180, 180, 180);
}

/*Map*/

.map-menu {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.map-menu-tile {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.selected-tile {
  background-color: var(--ui-active-color);
}

.map-menu-tile img {
  width: 18px;
  height: 18px;
}

.marker {
  cursor: pointer;
}

.marker img {
  pointer-events: none;
}

.marker-icon {
  position: absolute;
  top: 4px;
  left: 4px;
}

.mapboxgl-popup {
  cursor: default;
}

.mapboxgl-popup > .mapboxgl-popup-content {
  position: relative;
  background: var(--ui-panel-bg-color-1);
  min-width: 180px;
  max-width: 250px;
  color: rgb(220, 220, 220);
}

.hover-pop h4,
.mapboxgl-popup-content h4 {
  width: fit-content;
  max-width: calc(100% - 15px);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mapboxgl-popup-content h4 .utility {
  display: inline-block;
}

.mapboxgl-popup-content h4 .status-indicator {
  margin: 0px 2px;
}

.mapboxgl-popup-content h4 a {
  margin-right: 3px;
  color: rgb(220, 220, 220);
  cursor: pointer;
}

.mapboxgl-popup-content a:hover {
  text-decoration: underline;
}

.mapboxgl-popup-content p {
  width: calc(100% - 15px);
  font-size: 11px;
  font-weight: 400;
  color: rgb(180, 180, 180);
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.mapboxgl-popup-close-button {
  display: none;
}

.hover-pop {
  min-width: 180px;
  max-width: 250px;
  height: fit-content;
  padding: 8px;
  transform: translate(-50%, -100%);
  background-color: var(--ui-panel-bg-color-1);
  color: rgb(220, 220, 220);
}

.hover-pop h4 {
  padding: 0 5px;
}

.close-btn {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.status-indicator {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(180, 180, 180);
  display: inline-block;
  margin: 0 3px 1px;
  cursor: pointer;
}

.badge {
  width: max-content;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: rgba(161, 161, 161, 0.3);
  color: rgb(180, 180, 180);
  cursor: pointer;
  white-space: nowrap;
}

.tag {
  width: max-content;
  font-size: 11px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 4px 6px;
  border-radius: 2px;
  background-color: rgba(161, 161, 161, 0.3);
  color: rgb(180, 180, 180);
  cursor: pointer;
}

.map-controls {
  position: absolute;
  top: 50px;
  right: 8px;
  width: fit-content;
  height: fit-content;
  backdrop-filter: blur(5px);
  z-index: 200;
  cursor: pointer;
}

.map-control-btn {
  position: relative;
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: var(--ui-panel-bg-color-2);
  border: rgb(50, 50, 50) 1px solid;
  transition: all 300ms ease;
}

.map-control-btn:active {
  background-color: var(--ui-active-color);
}

.map-control-btn > .icon {
  width: 100%;
  height: 100%;
}

.map-control-btn.selected-tile {
  border-color: transparent;
}

.fullscreen-btn {
  margin: 10px 0;
}

.map-controls > .selected-tile {
  border: rgb(50, 50, 50) 1px solid;
}

/*Overview*/

#overview {
  position: relative;
  overflow-x: hidden;
  padding: 70px 10px 10px;
}

#overview > .side-menu {
  grid-column: 1 / span 3;
  grid-row: 1 / span 12;
  background-color: var(--ui-panel-bg-color-1);
  padding: 50px 15px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  opacity: 0;
  transform: translateX(-80px);
  animation: leftAppear 400ms ease forwards;
  transition: all 800ms ease;
}

.side-menu > .body {
  flex: 1;
  min-height: 120px;
  margin-top: 10px;
  overflow: auto;
}

.side-menu > .body > .asset-card {
  margin-left: 2px;
  width: calc(100% - 4px);
}

.side-menu > .body > p {
  font-size: 13px;
  color: rgb(160, 160, 160);
  text-align: center;
}

.side-menu > .select {
  margin: 6px 0;
}

.side-menu > .btn-group {
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  margin: 0;
  padding: 20px 0 0;
}

.side-menu > .plot-title > h4 > .small-icon {
  margin: 0 0 -1px 6px;
  transform: rotate(0deg);
  transition: all ease 300ms;
  opacity: 0;
}

#overview .map-container {
  position: relative;
  grid-column: 4 / span 9;
  grid-row: 1 / span 12;
  background-color: var(--ui-panel-bg-color-1);
  opacity: 0;
  animation: appear 600ms ease 400ms forwards;
  z-index: 1;
  overflow: hidden;
}

.map-container > .timeseries-control {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: clamp(250px, 100% - 20px, 750px);
  height: fit-content;
  opacity: 0;
  margin-bottom: -80px;
  animation: riseMargin 200ms ease-in forwards;
  transition: height 200ms ease;
}

.map-container > .timeseries-control > .player {
  height: 50px;
}

.map-container > .timeseries-control > .picker-container {
  position: relative;
  left: unset;
  top: unset;
  justify-content: center;
  margin-bottom: 10px;
}

.map-container > .timeseries-control > .picker-container > .picker {
  margin: 0 auto;
}

.map-container > .timeseries-control > .plot-container {
  position: relative;
  width: 100%;
  background-color: var(--ui-panel-bg-color-1);
  margin-top: 5px;
}

.query-menu > .body,
.color-legend > .body {
  overflow-y: unset;
}

.viz-controls {
  position: absolute;
  left: 10px;
  top: 50px;
  width: clamp(280px, 40%, 350px);
  height: calc(80% - 70px);
  background-color: transparent;
  pointer-events: none;
  transition: width ease 300ms;
}

.viz-controls > *,
.viz-controls > .body > * {
  pointer-events: all;
}

.viz-controls > .body {
  position: relative;
  max-height: calc(100% - 50px);
  overflow-y: unset;
  pointer-events: none;
}

.viz-controls-closed {
  width: 20px;
  transition: width ease 300ms;
}

.viz-controls-closed > .body {
  display: none;
}

.viz-controls > .tab-menu {
  position: relative;
  width: 100%;
  height: 30px;
  margin: 0;
  justify-content: flex-start;
}

.viz-controls > .tab-menu .hamburger {
  display: unset;
  max-width: 20px;
  position: absolute;
  right: 0;
}

.viz-controls > .tab-menu .hamburger-btn {
  margin-left: 0;
}

.tab {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  padding: 2.5px;
  border-bottom: transparent 1.5px solid;
  filter: brightness(75%);
  cursor: pointer;
}

.tab:hover {
  filter: unset;
}

.tab > .icon {
  width: 100%;
  height: 100%;
}

.active-tab {
  border-bottom: rgb(200, 200, 200) 1.5px solid;
  filter: unset;
}

.metrics-menu {
  position: relative;
  width: 100%;
  padding: 50px 10px 10px;
  background-color: var(--ui-panel-bg-color-1);
  overflow-y: hidden;
  transition: all ease 300ms;
}

.viz-controls > .body > .metrics-menu {
  max-height: 100%;
}

.metrics-card {
  position: relative;
  width: 100%;
  height: fit-content;
  margin-bottom: 6px;
  padding: 8px 5px;
  border-radius: 4px;
  border: transparent 1px solid;
  background-color: var(--ui-card-bg-color-1);
  transition: all ease 150ms;
  cursor: pointer;
}

.metrics-card > h5 {
  max-width: 100%;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  color: rgb(180, 180, 180);
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-metric {
  background-color: var(--ui-active-color-2);
}

.metrics-menu > .body {
  max-height: 400px;
  overflow-y: auto;
}

.metrics-menu > .body .number-input-container {
  width: calc(50% - 15px);
  max-width: calc(50% - 15px);
  min-width: calc(50% - 30px);
  padding: 0;
  margin: 0 15px 15px 0;
  display: inline-block;
}

.metrics-menu > .body label {
  text-transform: capitalize;
}

.color-legend {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 50px 0 15px;
  background-color: var(--ui-panel-bg-color-1);
}

.color-legend > .plot-title > h4 {
  text-transform: capitalize;
}

.color-legend > .body > .palette-picker,
.color-legend > .body > .checkbox,
.color-legend > .body > .select {
  padding: 0 15px;
}

.color-legend > .body > .select {
  margin: 0 0 20px;
}

.color-legend > .body > .select > .drop-down {
  width: calc(100% - 30px);
  left: 15px;
}

.color-legend > .body > .range-slider-container {
  margin-top: 30px;
  padding: 10px 15px;
}

#composition {
  position: relative;
  grid-column: 10 / span 3;
  grid-row: 1 / span 7;
  padding: 45px 15px 15px;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(20px);
  overflow-y: auto;
  opacity: 0;
  transform: translateX(80px);
  animation: rightAppear 400ms ease forwards;
}

.asset-card {
  position: relative;
  width: 100%;
  height: 75px;
  background-color: var(--ui-card-bg-color-1);
  padding: 10px;
  margin-bottom: 10px;
  border: transparent 1px solid;
  cursor: pointer;
  transition-duration: 250ms;
  transition-timing-function: ease;
}

.selected-card {
  border: var(--ui-active-color) 2px solid;
}

.selected-outline {
  outline: var(--ui-active-color) 2px solid;
}

.asset-card h4 {
  width: calc(100% - 15px);
  font-size: 12px;
  font-weight: 600;
  color: rgb(220, 220, 220);
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.asset-card h4 a {
  margin-right: 2px;
  color: rgb(220, 220, 220);
  cursor: pointer;
}

.asset-card h4 a:hover {
  text-decoration: underline;
}

.asset-card h4 .utility {
  display: inline-block;
}

.asset-card p {
  width: calc(100% - 15px);
  font-size: 11px;
  font-weight: 400;
  color: rgb(180, 180, 180);
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.asset-card .icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

/*Routing*/

#routing .map-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--ui-panel-bg-color-1);
  opacity: 0;
  animation: appear 600ms ease 400ms forwards;
}

.car-marker {
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.car-marker > img {
  width: 100%;
  height: auto;
}

.temp-marker {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.temp-marker > img {
  width: 18px;
  height: 18px;
}

.highlighted {
  outline: var(--ui-active-color) 2px solid;
}

#simulation-control {
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 10;
}

#simulation-control > .map-menu {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
}

#simulation-modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  z-index: 10;
  cursor: default;
}

#simulation-modal .btn-group {
  padding: 10px 5px;
  margin: 15px 0 0;
}

#simulation-modal .input-container {
  margin: 8px 0;
  padding: 0 8px;
}

#simulation-modal .input-container > label {
  font-size: 10px;
  color: rgb(160, 160, 160);
}

#simulation-modal .input-container > input[type=text],
#simulation-modal .input-container > input[type=number],
#simulation-modal .input-container > input:disabled {
  width: 100%;
  padding: 3px 8px;
  margin-top: 4px;
  color: rgb(180, 180, 180);
  font-size: 12px;
}

#simulation-modal > .input-container {
  width: 50%;
}

#simulation-modal > .hero-img {
  position: absolute;
  right: 5px;
  top: 68px;
  height: 150px;
  width: 150px;
  padding: 0 25px;
  z-index: 30;
}

#simulation-modal > .hero-img > svg {
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}

.flex-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap.flex-container .input-container:last-of-type {
  margin-left: 0;
}

/*Station*/

.station-card {
  width: 100%;
  height: max-content;
  background-color: rgb(6, 22, 37);
  padding: 10px;
}

.station-card > .badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.station-card p {
  margin: 6px 0 10px;
}

.station-card > .flex-container > .badge {
  margin-right: 8px;
}

.queue-plot > .queue-summary {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(20px - 50%));
  color: rgb(220, 220, 220);
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  transition-duration: 200ms;
}

/*======================================================*/

#not-found {
  position: relative;
  width: clamp(300px, 90%, 400px);
  text-align: center;
  color: rgb(180, 180, 180);
}

#not-found > h1 {
  color: rgba(240, 240, 240, 0.3);
  font-size: 120px;
  margin-bottom: 20px;
}

#not-found > h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

#not-found > p {
  margin-bottom: 20px;
}

#not-found > img {
  width: 60px;
  height: 60px;
}

#landing {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#landing .lottie-container {
  position: relative;
  height: clamp(300px, 100% - 200px, 500px);
  width: clamp(300px, 100%, 600px);
}

@keyframes facade {
  from {
    background-color: transparent;
    backdrop-filter: blur(0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(5px);
  }
}

@keyframes aavegIcon {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes aavegText {
  to {
    width: 0;
  }
}

.main-facade {
  position: absolute;
  left: 0;
  top: 5%;
  height: 90%;
  width: 100%;
  box-shadow: 0 0 30px rgba(19, 19, 19, 0.568);
  animation: facade 3200ms ease-in 4.5s forwards;
}

.main-facade > .aaveg-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 10vh;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  animation: aavegIcon 3200ms ease-in 4.5s forwards;
}

.main-facade > .aaveg-logo > .aaveg-icon {
  height: 100%;
  width: auto;
}

.main-facade > .aaveg-logo > .aaveg-text {
  height: 95%;
  width: fit-content;
  margin-top: 5%;
  margin-left: 4%;
  overflow: hidden;
  animation: aavegText 400ms ease-in 7.3s reverse backwards;
}

.aaveg-text img {
  height: 100%;
  width: auto;
}

.main-facade > h3 {
  position: absolute;
  left: 0;
  bottom: 30%;
  width: 100%;
  padding: 0 5%;
  color: rgb(220, 220, 220);
  font-size: clamp(18px, 10%, 28px);
  font-weight: 300;
  text-align: center;
  opacity: 0;
  animation: appear 300ms ease-in 7.5s forwards;
}

#landing > .btn-group {
  margin: 40px 0 80px;
  width: clamp(280px, 90%, 350px);
  opacity: 0;
  animation: appear 300ms ease-in 1s forwards;
}

/*Player*/

.player {
  position: relative;
  background-color: var(--ui-panel-bg-color-1);
  display: flex;
}

.player > .slider-container {
  padding: 18px 0 10px;
}

.player > .slider-container .slider > .thumb {
  transform: translateX(-50%) scale(0.5);
} 

.player > .slider-container:hover .slider > .thumb {
  transform: translateX(-50%) scale(1);
} 

.play-controls {
  position: relative;
  width: 50px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-controls > .icon {
  width: 20px;
  height: 20px;
  filter: brightness(0.8);
  cursor: pointer;
}

.play-controls > .icon:hover {
  filter: brightness(1);
}

.slider-container .slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(180, 180, 180);
  transform: translateX(-50%);
}

/*Pages*/

.page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgb(4, 32, 56);
  overflow-y: auto;
}

.blank-page {
  position: relative;
  width: 100%;
  height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-flex {
  flex-direction: column;
}

.std-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.std-grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  grid-gap: 1.2rem;
}

.contents {
  overflow-x: hidden;
}

.block-center {
  position: relative;
  width: clamp(300px, 100%, 800px);
  min-height: 100%;
  margin: 0 auto;
}

/*Layout*/

section {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
}

section > h1,
section > h2,
section > h3,
section > h4,
section > h5 {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: rgb(220, 220, 220);
}

section > h1 {
  font-size: 20px;
  color: rgb(240, 240, 240);
}

section > h2 {
  font-size: 16px;
}

section > h3 {
  font-size: 14px;
  color: rgb(212, 212, 212);
}

section > h4 {
  font-size: 13px;
  color: rgb(190, 190, 190);
}

section p {
  position: relative;
  margin-bottom: 10px;
  font-size: 13px;
  color: rgb(180, 180, 180);
}

section > .section-title {
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  color: rgb(180, 180, 180);
  text-transform: uppercase;
}

section > .picker-container {
  position: relative;
  left: unset;
  top: unset;
  height: fit-content;
  padding: 0 0 10px;
}

/*Git graph*/

.git-graph-container {
  position: relative;
  width: fit-content;
  max-width: 100%;
  padding: 0 0 10px 0;
}

.git-graph-container > .select {
  width: fit-content;
  float: right;
  margin-top: 10px;
}

.git-graph {
  position: relative;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  font-size: 12px;
  color: rgb(160, 160, 160);
  overflow-x: auto;
}

.git-hint.hint .crosshair-value {
  font-size: 9.5px;
}

.git-hint.hint h4.crosshair-title {
  font-size: 10px;
}

/*Profile*/

#profile {
  padding: 70px 0 0;
}

#profile > .block-center {
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
}

#profile .vertical-menu {
  position: relative;
  width: 20%;
  min-width: 220px;
  max-width: 280px;
}

#profile .vertical-menu .vertical-menu-item {
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  padding: 0 20px;
  background-color: var(--ui-panel-bg-color-2);
}

#profile .vertical-menu .vertical-menu-item:hover {
  background-color: rgb(26, 26, 26);
}

#profile .vertical-menu .selected-menu-item {
  background-color: var(--ui-active-color-2);
}

#profile .vertical-menu .selected-menu-item:hover {
  background-color: var(--ui-active-color-2);
}

#profile .block {
  position: relative;
  min-height: 300px;
  padding: 0 10px;
  width: clamp(300px, 80%, 100% - 220px);
}

#profile .block > .loader-frame {
  min-height: 260px;
}

#profile form {
  max-width: 500px;
  padding: 20px;
  background-color: var(--ui-panel-bg-color-2);
}

#profile form > .flex-container {
  justify-content: space-between;
}

#profile form > .btn-group button {
  width: fit-content;
  max-width: 180px;
}

#profile form .image-upload {
  max-width: 100%;
}

#profile form #image-upload-container {
  position: relative;
  width: 150px;
  margin-right: 20px;
}

#profile form .flex-child {
  min-width: 200px;
}

.flex-child {
  flex: 1;
}

#user-invites .card,
.session-card {
  max-width: 500px;
  padding-top: 45px;
  background-color: rgb(2, 12, 22);
  box-shadow: var(--ui-std-box-shadow-1);
}

#user-invites .card > .title,
.session-card > .title {
  background-color: var(--ui-card-bg-color-1);
  border: none;
  padding: 10px;
  height: fit-content;
  display: block;
  white-space: nowrap;
}

.session-card > .title {
  display: flex;
  align-items: center;
}

.session-card {
  width: 100%;
  max-width: 400px;
}

.session-card > .title {
  font-size: 12px;
}

#user-invites .card > .action-icon {
  z-index: 20;
}

#user-invites > .center-absolute > h3 {
  font-weight: 400;
  text-align: center;
  color: rgb(160, 160, 160);
}

#user-invites > .center-absolute > .large-icon {
  position: relative;
  margin: 10px auto;
}

#user-invites,
#user-activity {
  position: relative;
  min-height: 400px;
}

#user-activity > section {
  width: fit-content;
  max-width: 100%;
}

#user-activity > section > h2 > .select {
  width: fit-content;
  margin-left: auto;
}

#user-activity > .body {
  background-color: rgb(6, 22, 37);
}

#user-invites > .loader-frame,
#user-activity > .loader-frame {
  height: 260px;
}

#user-invites > button {
  background-color: #c98313;
  padding-left: 15px;
  padding-right: 15px;
  height: 34px;
}

#user-invites .card > .icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

#user-invites .card .input-container {
  margin: 15px 0;
}

#user-invites .card .btn-group {
  margin-top: 20px;
}

#add-invite .input-container {
  margin: 10px 0;
}

.action-icon {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  padding: 5px;
  background-color: var(--ui-card-bg-color-2);
  cursor: pointer;
  transition: all ease 50ms;
}

.action-icon-active {
  background-color: var(--ui-active-color-2);
  cursor: default;
}

.action-icon:not(.action-icon-active):hover {
  background-color: var(--ui-card-bg-color-3);
}

/*Metrics*/

#metrics {
  position: relative;
  padding: 70px 10px 10px;
}

/*Incidents*/

#incidents {
  position: relative;
  padding: 70px 10px 10px;
}

#metrics > .block-center,
#incidents > .block-center {
  position: relative;
  min-height: 200px;
}

#metrics > .block-center > .loader-frame,
#incidents > .block-center > .loader-frame {
  min-height: 200px;
}

#metrics > .block-center > .table-container,
#incidents > .block-center > .table-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
}

.table-container > .body {
  height: 300px;
}

#metrics > .block-center > .metric > .body,
.incident {
  height: max-content;
  max-height: none;
}

#metrics > .block-center > .metric > h3,
.incident > h3,
.table-container > h3 {
  font-weight: 400;
  color: rgb(180, 180, 180);
  margin: 10px 0 15px;
  display: flex;
  align-items: center;
}

#metrics > .block-center > .metric > .body,
.incident > .body {
  font-size: 13px;
  color: rgb(180, 180, 180);
}

#metrics > .block-center > .metric > .body > h6,
.incident > .body > h6 {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 20px 0 5px 0;
  color: rgb(140, 140, 140);
}

#metrics > .block-center > .metric .flex-container,
.incident > .body > .flex-container {
  margin: 10px 0;
}

#metrics > .block-center > .metric .flex-container > .badge,
.incident .flex-container > .badge {
  margin-bottom: 10px;
  margin-right: 10px;
}

#metrics > .block-center > .metric > .body > .flex-container > .icon,
.incident > .body > .flex-container > .small-icon {
  margin-right: 6px;
}

.incident > .download {
  margin: 20px 0;
}

#metrics > .block-center > .metric > .flex-container > .link,
.incident > .flex-container > .link {
  font-size: 13.5px;
  margin: 30px 0;
}

.table-container > h3 > button {
  margin: 0 0 0 auto;
}

.table-container > .controls {
  margin-bottom: 20px;
  max-height: 500px;
}

.table-container > .controls-closed {
  max-height: 0;
}

.table-container > .controls > div {
  margin: 15px 0;
}

.table-container > .controls > .flex-container:first-of-type {
  justify-content: space-between;
  flex-wrap: wrap;
}

.table-container > .controls > .flex-container:last-of-type {
  margin-top: 40px;
}

.table-container > .controls > .flex-container > .flex-container button {
  margin-right: 20px;
}

.plain-flex {
  position: relative;
  width: 100%;
  display: flex;
}

.table-container > .flex-container {
  margin: 15px 0;
  justify-content: space-between;
}

.table-container > .flex-container .icon {
  cursor: pointer;
}

.table-container > .flex-container > .icon:first-of-type {
  transform: rotate(180deg);
}

.status-table {
  border-collapse: collapse;
  background-color: var(--ui-panel-bg-color-1);
}

.status-table tr {
  background-color: var(--ui-card-bg-color-1);
  height: 35px;
}

.status-table tr.selected-row {
  background-color: var(--ui-card-bg-color-3);
}

.status-table thead > tr {
  background-color: var(--ui-card-bg-color-2);
  color: rgb(200, 200, 200);
  text-transform: capitalize;
}

.status-table tbody > tr:hover {
  background-color: var(--ui-card-bg-color-2);
}

.status-table td, th {
  padding: 8px 10px;
  font-weight: 400;
  text-transform: capitalize;
}

.status-table .icon {
  z-index: 10;
  cursor: pointer;
}

.page-numbers {
  position: relative;
  display: flex;
  align-items: center;
  color: rgb(160, 160, 160);
  font-size: 13px;
}

.page-number {
  margin-right: 20px;
}

.page-numbers > .page-number:not(.current-page) {
  cursor: pointer;
  text-decoration: underline;
}

.page-numbers > .page-number:not(.current-page):hover {
  color: rgb(180, 180, 180);
}

.page-numbers > .current-page {
  color: #c98313;
  text-decoration: none;
  cursor: default;
}

.download {
  position: relative;
  width: 100%;
  padding: 15px;
  color: rgb(216, 216, 216);
  font-size: 13px;
  border: rgb(40, 40, 40) 1px solid;
}

.download > .flex-container {
  align-items: center;
}

.download > .flex-container > * {
  margin-right: 15px;
}

.download > .flex-container h6 {
  margin-top: 2px;
  font-weight: 500;
  font-size: 11.5px;
  color: rgb(180, 180, 180);
}

/*Controls*/

.controls {
  position: relative;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  overflow: hidden;
  transition: all 250ms ease;
}

.controls .link {
  width: fit-content;
  font-size: 13px;
}

.controls-closed {
  max-height: 0;
}

.controls .range-slider-container {
  max-width: 400px;
  padding: 0;
  margin-top: 15px;
}

.incident-card {
  position: relative;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  padding-left: 10px;
  border-radius: 3px;
  background-color: rgb(2, 12, 22);
  border-left: rgb(21, 102, 224) 5px solid;
}

.incident-card > h6,
.incident-card > h5,
.incident-card > p {
  margin: 2px 0;
}

.incident-card > h6 {
  color: rgb(200, 200, 200);
  font-size: 10.5px;
  font-weight: 400;
}

.incident-card > h5 {
  display: flex;
  align-items: center;
  color: rgb(160, 160, 160);
  font-size: 10.5px;
  font-weight: 400;
}

.incident-card > h5 > .small-icon {
  width: 11px;
  margin: 0 3px 0 0;
}

.incident-card > p {
  color: rgb(180, 180, 180);
  font-size: 13px;
}

.incident-card > p:first-letter {
  text-transform: uppercase;
}

/*Channels*/

#channels {
  position: relative;
  grid-column: 4 / span 9;
  grid-row: 2 / span 11;
  padding: 10px;
}

.channel-card {
  position: relative;
  width: auto;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(6, 22, 37);
  transition: ease 300ms;
}

.video-error,
.channel-card > .loader-frame {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgb(180, 180, 180);
  font-size: 13px;
}

.channel-card iframe {
  border: none;
}

/*Signup*/

#signup > a {
  margin: 20px 0 0;
  font-size: 13px;
}

/*Add station*/

#add-station {
  padding: 70px 10px 20px;
}

#add-station > .form-progress {
  grid-row: 1 / span 1;
  grid-column: 1 / span 12;
}

#add-station > .form-progress > .form-step {
  flex: 1;
}

#add-station > #basic-details {
  grid-row: 2 / span 10;
  grid-column: 1 / span 5;
  padding: 40px 20px 10px;
  background-color: var(--ui-panel-bg-color-2);
}

#basic-details textarea {
  max-height: 90px;
}

#add-station > .map-container {
  position: relative;
  grid-row: 2 / span 10;
  grid-column: 6 / span 7;
  padding: 40px 0 0;
  background-color: var(--ui-panel-bg-color-2);
}

#add-station > .map-container > .plot-title > p {
  position: relative;
  margin: 4px 0 0 15px;
  font-size: 12px;
  color: rgb(160, 160, 160);
  font-family: 'Courier New', Courier, monospace;
  white-space: nowrap;
}

#station-coordinates {
  position: absolute;
  right: 0;
  top: 40px;
  width: clamp(250px, 50%, 350px);
  height: max-content;
  padding: 40px 15px 15px;
  background-color: var(--ui-panel-bg-color-2);
  z-index: 100;
}

#station-coordinates .plot-title {
  position: absolute;
  left: 0;
  top: 0;
}

#station-coordinates > .body > .flex-container {
  margin: 0 0 15px;
}

#station-coordinates > .body > .flex-container > .input-container:last-of-type {
  margin-left: 15px;
}

#add-station > .btn-group {
  position: relative;
  grid-row: 12 / span 1;
  grid-column: 1 / span 12;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
}

#add-station > #add-bays {
  position: relative;
  grid-row: 2 / span 10;
  grid-column: 1 / span 12;
  padding: 40px 0 50px;
  background-color: var(--ui-panel-bg-color-2);
}

#add-bays > #bay-modal {
  position: absolute;
  right: 0;
  top: 40px;
  height: fit-content;
  width: 260px;
  padding: 15px;
  background-color: var(--ui-panel-bg-color-2);
  backdrop-filter: blur(10px);
  color: rgb(160, 160, 160);
  border: rgb(50, 50, 50) 1px solid;
  font-size: 12px;
  z-index: 30;
}

#bay-modal > .flex-container {
  padding: 0;
  margin-bottom: 15px;
  align-items: flex-start;
  justify-content: flex-start;
}

#bay-modal > .flex-container > .input-container {
  width: fit-content;
  margin: 3px 6px 3px 0;
  padding: 10px;
}

#bay-modal .checkbox {
  margin-bottom: 20px;
}

#bay-modal input[type=number] {
  width: 100%;
  height: 40px;
  padding: 6px 24px 6px 10px;
}

#add-station > .btn-group > button {
  max-width: 120px;
}

#add-bays > .body.flex-container {
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  height: max-content;
  max-height: 100%;
}

#add-bays > .btn-group {
  position: absolute;
  bottom: 15px;
  left: 0;
}

.bay-card {
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  border-radius: 4px;
  border: rgb(60, 60, 60) 1px solid;
  margin: 6px 6px 0 0;
}

.bay-card .icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.bay-card > .flex-container > .input-container {
  width: fit-content;
  margin: 3px 6px;
  padding: 0;
}

.bay-card > .input-container {
  padding: 3px 6px;
  margin: 0;
  margin-bottom: 4px;
}

.bay-card .option-area {
  width: 150px;
  margin-top: 5px;
  margin-bottom: 0;
}

.bay-card .number-input {
  width: 50px;
  height: 30px;
}

.bay-card input[type=number] {
  width: 100%;
  height: 100%;
  padding: 6px 15px 6px 10px;
  margin-top: 5px;
}

.bay-card input[type=text] {
  width: 150px;
  height: 30px;
  display: block;
  margin-top: 5px;
  padding: 6px;
}

.bay-card label {
  font-size: 10px;
}

#bay-modal .number-input-btn-up, 
#bay-modal .number-input-btn-down, 
.bay-card .number-input-btn-up, 
.bay-card .number-input-btn-down {
  width: 18px;
}

#add-station > .plot-editor {
  position: relative;
  grid-row: 2 / span 10;
  grid-column: 7 / span 6;
  padding: 40px 0;
  background-color: var(--ui-panel-bg-color-2);
}

#add-station > .minimap-container {
  position: relative;
  grid-row: 2 / span 10;
  grid-column: 1 / span 6;
  padding: 40px 0;
  background-color: var(--ui-panel-bg-color-2);
}

#add-station > .minimap-container > .body {
  padding: 0 10px;
  overflow: hidden;
}

/*Editor*/

.plot-editor > .body {
  position: relative;
  height: calc(100% - 40px);
  padding: 0;
}

.plot-editor > .plot-title > .drop-menu p {
  text-transform: none;
}

.plot-editor > .btn-group {
  position: absolute;
  bottom: 20px;
  left: 0;
  margin: 0;
  padding: 0 15px;
}

/* .plot-editor > .btn-group > button {
  max-width: 120px;
  width: max-content;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/*Asset*/

#asset {
  position: relative;
  padding: 60px 0 0;
  grid-row-gap: 0;
}

#asset > .left-menu {
  position: relative;
  grid-column: 1 / span 3;
  grid-row: 1 / span 12;
  padding: 10px;
  overflow-y: auto;
  opacity: 0;
  transform: translateX(-80px);
  animation: leftAppear 400ms ease forwards;
}

.station-status-card {
  position: relative;
  width: 100%;
  background-color: rgb(6, 22, 37);
}

.station-status-card > h5 {
  font-size: 13px;
  font-weight: 500;
}

.station-status-card > h5 > .icon {
  margin-right: 6px;
}

.station-status-card > p {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 6px;
}

.station-status-card > p > .small-icon {
  margin: 2.5px 5px 0 0;
}

#asset > .contents {
  position: relative;
  grid-column: 4 / span 9;
  grid-row: 2 / span 11;
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

#asset > .contents > .loader-frame {
  position: absolute;
  left: 0;
  top: 0;
}

#asset > .sub-menu {
  position: relative;
  grid-column: 4 / span 9;
  grid-row: 1 / span 1;
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
}

.sub-menu > a {
  height: 80%;
}

.sub-menu > .data-status {
  position: absolute;
  right: 15px;
  color: rgb(160, 160, 160);
  font-size: 13px;
}

.sub-menu-item {
  position: relative;
  height: 100%;
  padding: 0 15px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: rgba(0, 0, 0, 0) 3.5px solid;
  transition-duration: all 400ms ease;
}

.sub-menu-item:not(.current-item) {
  filter: brightness(75%);
}

.sub-menu-item:not(.current-item):hover {
  border-bottom: rgba(120, 120, 120, 0.75) 3.5px solid;
}

.current-item {
  border-bottom: var(--ui-active-color) 3.5px solid;
}

.sub-menu-item > h4 {
  font-weight: 400;
  margin-left: 8px;
  font-size: 14px;
  color: #fafafa;
  text-transform: capitalize;
}

.sub-menu-item > .icon {
  margin: 2px 0 0;
}

/*Share*/

.share-link > input[type=url] {
  padding-right: 40px;
}

.share-link.input-container > .action-icon {
  bottom: 0;
  right: 0;
  transform: translate(-5px, -6px);
}

.sep {
  position: relative;
  width: 100%;
  height: 5px;
  margin: 20px 0 15px;
  border-top: rgb(40, 40, 40) 1px solid;
}

/*Snapshots*/

.snapshot {
  position: relative;
  width: clamp(280px, 100%, 500px);
  min-height: 200px;
  padding: 40px 10px 10px;
  background-color: var(--ui-panel-bg-color-1);
}

.snapshot .input-container {
  margin-bottom: 10px;
}

.snapshot textarea {
  height: 120px;
  min-height: 80px;
  max-height: 300px;
}

/*Station Reports*/

.contents#station-reports {
  display: block;
}

.contents#station-reports > .picker-container {
  position: relative;
  left: unset;
  top: unset;
  margin: 20px 0 40px;
  padding: 0;
}

.contents#station-reports > .congestion-plot-container {
  height: 50%;
}

/*Schematic*/

.contents > .station-map {
  position: relative;
  grid-column: 1 / span 8;
  grid-row: 1 / span 12;
  padding: 90px 0 50px;
  margin-bottom: 10px;
  background-color: rgb(6, 22, 37);
  opacity: 0;
  animation: appear 300ms ease forwards;
}

.station-map > .title {
  border: none;
}

.station-map > .body {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px 15px 0;
  overflow: hidden;
}

.station-map > .body > .summary-table-container {
  height: 100%;
  overflow-y: auto;
}

.pan {
  cursor: grab;
}

.drag {
  cursor: grabbing;
}

.move {
  cursor: move;
}

.station-map > .plot-controls {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: fit-content;
}

.station-map > .plot-controls > .map-menu-tile {
  width: 26px;
  height: 26px;
  margin: 0 6px;
}

.station-map > .plot-controls > .map-menu-tile:not(.selected-tile) {
  background-color: rgba(150, 150, 150, 0.15);
}

.station-map > .plot-controls > .map-menu-tile:not(.selected-tile):hover {
  background-color: var(--ui-card-bg-color-2);
}

.station-map > .plot-controls > .plot-zoom {
  position: relative;
  width: 220px;
  height: 26px;
  padding: 0 6px;
  background-color: rgba(150, 150, 150, 0.15);
}

.station-map > .plot-controls > .plot-zoom > .icon {
  cursor: pointer;
}

.station-map > .plot-controls > .plot-zoom > .range-slider-container {
  padding: 0 8px;
}

.station-map > .plot-controls > .plot-zoom > p {
  width: 35px;
  min-width: 35px;
  padding: 0 0 0 5px;
  font-size: 12.5px;
  color: rgb(180, 180, 180);
}

.bay-info {
  position: relative;
  width: 250px;
  height: 200px;
  color: rgb(180, 180, 180);
  z-index: 20;
}

.bay-info h4 {
  position: relative;
  font-weight: 400;
  font-size: 14px;
}

.bay-info p {
  position: relative;
  font-weight: 400;
  font-size: 13px;
}

.bay-info h4 .utility {
  display: inline-block;
}

.bay-info > .flex-container {
  margin: 10px 0;
}

.bay-info > .flex-container > .badge {
  margin-right: 8px;
}

.bay-info > .small-hint {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 200ms ease;
}

.bay-info > .small-hint > p {
  font-size: 12px;
  color: rgb(160, 160, 160);
  text-align: right;
  margin-bottom: 5px;
}

.mini-composition,
.mini-composition.flex-container {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 50px;
  margin: 0;
  display: flex;
  align-items: center;
}

.mini-composition .bubble {
  top: -3px;
}

.mini-composition .vehicle-card {
  height: fit-content;
  flex: 1;
  max-width: 60px;
  margin: 0;
  background-color: transparent;
}

.mini-composition .vehicle-card .icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(150, 150, 150, 0.2);
  cursor: pointer;
}

.contents > .utilization {
  position: relative;
  grid-column: 9 / span 4;
  grid-row: 1 / span 7;
  padding: 40px 0 0;
  overflow-y: auto;
  opacity: 0;
  animation: rightAppear ease 400ms forwards;
}

.utilization > .leaderboard {
  position: relative;
  width: 100%;
}

.contents > .vehicle-data {
  position: relative;
  grid-column: 9 / span 4;
  grid-row: 8 / span 5;
  padding: 40px 0 0;
  overflow-y: auto;
  opacity: 0;
  animation: rightAppear ease 600ms forwards;
}

.vehicle-card {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background-color: rgb(6, 22, 37);
  display: flex;
  align-items: center;
}

.vehicle-card > .vehicle-trend {
  position: relative;
  height: 100%;
  flex: 1;
  min-width: calc(100% - 90px);
}

.vehicle-card .icon {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(150, 150, 150, 0.2);
  cursor: pointer;
}

.vehicle-card > .vehicle-count {
  position: relative;
  width: 50px;
  height: 40px;
  transition: all 300ms ease;
}

.vehicle-count {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.vehicle-count > h4 {
  font-size: 25px;
  color: rgb(180, 180, 180);
  font-weight: 500;
  grid-column: 1 / span 3;
  grid-row: 1 / span 2;
  text-align: right;
  padding-right: 2px;
}

.vehicle-count > .up {
  color: rgb(180, 180, 180);
  font-weight: 600;
  grid-column: 4 / span 2;
  grid-row: 1 / span 1;
}

.vehicle-count > .down {
  color: rgb(180, 180, 180);
  font-weight: 600;
  grid-column: 4 / span 2;
  grid-row: 2 / span 1;
}

.vehicle-count > .up, .vehicle-count > .down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vehicle-count p {
  font-size: 10px;
}

.vehicle-count .small-icon {
  width: 10px;
  height: 10px;
  margin: 1px;
  padding: 1px;
}

/*Dashboard*/

.contents > .congestion-plot-container {
  position: relative;
  grid-column: 1 / span 12;
  grid-row: 1 / span 9;
  max-width: 80%;
  padding: 90px 15px 15px;
  background-color: rgb(6, 22, 37);
  opacity: 0;
  animation: appear 300ms ease 250ms forwards;
}

.congestion-plot-container > .title {
  border: none;
}

.picker-container {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  overflow: hidden;
  transition: all 300ms ease;
}

.picker-container-closed {
  height: 0;
  max-height: 0;
}

.picker-container > button {
  margin-left: 15px;
}

.congestion-plot-container > .congestion-plot {
  position: relative;
  width: 100%;
  height: clamp(100% - 70px, 80%, 100% - 40px);
}

.legend {
  position: relative;
  width: 100%;
  height: clamp(40px, 20%, 70px);
  padding: 0 10px;
  font-size: 12px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.legend > .legend-item {
  width: fit-content;
  margin: 0 15px 10px 0;
  color: rgb(180, 180, 180);
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.legend-item > .square {
  margin-right: 5px;
}

.legend > .inactive {
  color: rgb(110, 110, 110);
}

.legend > .legend-item:hover {
  color: rgb(160, 160, 160);
}

.contents > .window-slider-frame {
  position: relative;
  grid-column: 1 / span 8;
  grid-row: 10 / span 2;
  padding: 0 15px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: appear 300ms ease 250ms forwards;
}

.window-slider {
  position: relative;
  height: 60px;
  width: 100%;
  background-color:rgb(6, 22, 37);
  filter: brightness(0.5);
}

.window-slider-track {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0 calc(100% / 48);
}

.window-slider-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.window-slider-backdrop .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay > rect {
  width: 100%;
  height: 100%;
  fill: rgba(15, 15, 15, 0.5);
  display: none;
}

.window-slider > input[type="range"] {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-appearance: none;
}

.window-slider > input[type="range"]::-webkit-slider-thumb {
  height: 60px;
  width: calc(100% / 24);
  -webkit-appearance: none;
  border: 0 none;
  pointer-events: all;
  cursor: move;
}

.window {
  height: 100%;
  width: calc(100% / 24);
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  filter: brightness(100%);
  border: rgb(50, 50, 50) 1px solid;
  pointer-events: none;
  z-index: 3;
  transition: all 300ms ease;
  filter: brightness(1.5);
  mix-blend-mode: lighten;
}

.window-slider-frame > .custom-axis {
  position: absolute;
  bottom: 0;
  left: -5px;
  width: calc(100% + 10px);
  height: 40px;
}

/*Metrics*/

#station-metrics.contents,
#station-protocols.contents {
  display: block;
}

.contents > .plot {
  position: relative;
  width: 100%;
  max-width: 80%;
  height: 50%;
  padding: 50px 0 15px;
  margin-bottom: 10px;
  background-color: rgb(6, 22, 37);
  display: flex;
  opacity: 0;
  animation: appear 300ms ease 250ms forwards;
}

.plot > .body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.timeseries .instruction {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: rgb(180, 180, 180);
  background-color: rgba(255, 255, 255, 0.1);
}

.timeseries .btn-group {
  margin: 10px 0;
}

.timeseries .btn-group button {
  height: 26px;
  flex: unset;
}

.timeseries .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.timeseries .overlay > * {
  pointer-events: all;
}

.contents > .pop-times {
  animation: appear 300ms ease 250ms forwards;
}

.pop-times > .usual-plot {
  position: relative;
  width: 100%;
  height: 100%;
}

.contents > .cumulative-counts {
  padding: 40px 0 15px;
  animation: appear 300ms ease 350ms forwards;
}

.contents > .cumulative-counts > .body {
  padding-top: 25px;
  overflow: hidden;
}

.cumulative-counts > .body > .radio-group {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
  padding: 0 15px;
}

.cumulative-counts > .body > .radio-group > .flex-container > .flex-container {
  max-width: 150px;
}

.contents > .stats {
  position: relative;
  grid-column: 9 / span 4;
  grid-row: 1 / span 6;
  padding: 50px 15px 15px;
  background-color: rgb(6, 22, 37);
  display: flex;
  opacity: 0;
  animation: appear 300ms ease 250ms forwards;
}

.stats > .body h5 {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(200, 200, 200);
  width: fit-content;
}

.stats > .body p {
  font-size: 12px;
  color: rgb(200, 200, 200);
  width: fit-content;
  margin: 10px 0;
}

.body p > b {
  font-weight: 600;
}

.body > .flex-container > .capsule-badge {
  font-size: 10px;
  margin: 0 6px 4px 0;
}

.body > .flex-container > h5 {
  white-space: nowrap;
  margin: 0 6px 4px 0;
}

.contents > .temp {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 400;
  color: rgb(180, 180, 180);
}

.contents > .metric {
  position: relative;
  width: clamp(200px, 100%, 450px);
  margin: 0 5px 5px 0;
  padding: 50px 15px 15px;
  background-color: rgb(6, 22, 37);
  opacity: 0;
  animation: appear 300ms ease 250ms forwards;
}

.metric > .body {
  min-height: 200px;
  max-height: 400px;
}

.metric > .body > table td:nth-child(odd) {
  text-transform: capitalize;
}

.metric > h6 {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 11.5px;
  color: rgb(180, 180, 180);
  text-align: right;
}

.metric > .body > .legend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.metric > .body > .legend > .legend-item {
  display: flex;
  align-items: center;
  margin: 3px 5px;
  color: rgb(220, 220, 220);
  font-size: 11px;
  text-transform: capitalize;
}

.metric > .body > .legend > .legend-item.inactive {
  color: rgb(180, 180, 180);
}

/*Protocols*/

#station-protocols .box {
  position: relative;
  width: 100px;
  height: 80px;
  padding: 10px;
  margin: 15px 15px 0 0;
  border-radius: 4px;
  border: rgb(60, 60, 60) 1px solid;
}

.text-center {
  text-align: center;
}

.box > h3 {
  font-size: 24px;
  font-weight: 500;
  color: rgb(190, 190, 190);
}

.prelim {
  position: relative;
  width: 100%;
  height: 50%;
  min-height: 250px;
  max-height: 400px;
  transition: all 300ms ease;
}

.settled {
  max-height: none;
  height: fit-content;
}

/*Reports*/

#reports {
  position: relative;
  padding: 70px 10px 10px;
}

#reports > .block-center,
#reports section section {
  padding: 0;
}

#reports > .block-center > h3 {
  font-weight: 400;
  color: rgb(180, 180, 180);
  margin: 10px 0 15px;
  display: flex;
  align-items: center;
}

#reports > .block-center > .body {
  margin-top: 20px;
  padding: 0 0 10px;
  min-height: 300px;
}

#reports > .block-center > .body > .loader-frame {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#reports > .block-center > .flex-container {
  margin: 20px 0 0;
}

#reports > .block-center > .flex-container:first-of-type {
  justify-content: space-between;
}

#reports > .block-center .box {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#reports > .block-center > .flex-container > .btn-group {
  width: fit-content;
}

#reports > .block-center > .flex-container > .btn-group > button {
  height: 28px;
  padding: 0 15px;
}

#reports > .block-center > .flex-container > .btn-group > button.border-btn {
  height: 29px;
}

#reports > .block-center .report-progress-card {
  width: 100%;
}

#reports > .block-center > .btn-group {
  margin-top: 20px;
}

#reports > .block-center a > .secondary-btn {
  height: 30px;
  padding: 0 15px;
  background-color: rgb(160, 160, 160);
}

.report-progress-card {
  padding: 0 0 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(15, 15, 15, 0.15);
}

.report-progress-card:last-of-type {
  margin-bottom: unset;
}

.report-progress-card > .flex-container:first-of-type {
  padding: 4px 10px;
  background-color: rgba(50, 50, 50, 0.5);
  justify-content: space-between;
}

.report-progress-card > p {
  padding: 0 10px;
  margin: 10px 0 0;
  font-size: 11px;
}

.report-progress-card h4 {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: calc(100% - 150px);
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
}

.report-progress-card h4 > a {
  color: rgb(180, 180, 180);
}

.report-progress-card h4 > a:hover {
  color: rgb(160, 160, 160);
  text-decoration: underline;
}

.report-progress-card h4 > img,
.report-progress-card h4 > .loader-frame {
  margin-right: 6px;
}

.report-progress-card .timestamp {
  font-size: 11px;
  margin: 5px 0;
}

.report-progress-card > .sep {
  margin: 10px 0 5px;
}

.report-progress-card > .progress-container {
  padding: 0 10px;
}

.report-builder {
  width: clamp(250px, 100%, 500px);
  height: fit-content;
  max-height: 90%;
}

/*Banner*/

.banner {
  position: relative;
  width: 100%;
  padding: 0 10px;
  font-size: 13px;
  color: rgb(240, 240, 240);
  max-height: 0;
  border-radius: 4px;
  transition: all ease 300ms;
  overflow: hidden;
}

.banner > .close-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.banner-open {
  max-height: 100px;
  padding: 5px 30px 6px 10px;
}

.report-builder .banner-open {
  background-color: rgb(194, 5, 5);
}

.report-builder .picker-container {
  position: relative;
  left: unset;
  top: unset;
  padding: 0;
  height: 60px;
  margin-top: -10px;
}

.report-builder .input-container {
  margin: 10px 0 20px;
}

.metric-summary,
.metric-quality {
  margin: 20px 0;
}

.controller {
  position: relative;
  padding: 40px 0 0;
  margin-bottom: 20px;
}

.controller > .flex-container:first-of-type {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: space-between
}

nav {
  position: relative;
  display: flex;
  align-items: center;
}

nav > .sub-menu-item {
  color: rgb(180, 180, 180);
  padding: 5px;
  margin-right: 10px;
  font-size: 13.5px;
  text-transform: capitalize;
  border: unset;
  border-bottom: rgb(80, 80, 80) 3px solid;
}

nav > .sub-menu-item:not(.current-menu-item):hover {
  border-bottom: rgb(120, 120, 120) 3px solid;
}

nav > .current-menu-item {
  color: rgb(240, 240, 240);
  border-color: var(--ui-active-color);
  background-color: unset;
}

nav > .sub-menu-item.current-menu-item:hover {
  border-bottom: var(--ui-active-color-2) 3px solid;
}

/*Sort*/

.sort {
  position: relative;
  width: fit-content;
  margin: 10px 0;
  border: rgb(60, 60, 60) 1px solid;
  border-radius: 4px;
  background-color: var(--ui-card-bg-color-1);
}

.sort > .select {
  margin: 0;
}

.sort > .select > .current-menu-item {
  border-radius: 0;
  border: hidden;
  border-left: rgb(60, 60, 60) 1px solid;
  border-right: rgb(60, 60, 60) 1px solid;
}

.sort > .icon-container,
.sort > .sort-order {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort > .sort-order {
  cursor: pointer;
}

.sort > .sort-order > .icon {
  transition: all ease 150ms;
}

/*Progress*/

.progress-container > h6.progress-text {
  position: relative;
  height: 15px;
  min-height: 15px;
  color: rgb(180, 180, 180);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
}

.progress-container p.progress-value {
  margin-left: 10px;
  max-width: 50px;
  font-size: 11px;
}

.progress-container progress {
  flex: 1;
}

progress {
  position: relative;
  width: 100%;
  height: 4px;
  appearance: none;
  -webkit-appearance: none;
}

progress::-webkit-progress-bar {
  background-color: rgb(48, 48, 48);
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background-color: var(--ui-active-color);
  border-radius: 2px;
  transition: width ease 200ms;
}

/*Report Template*/

.report {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.report .badge {
  margin: 0 6px 6px 0;
}

/*General Picker*/

.general-picker {
  position: relative;
  width: 100%;
}

.general-picker .current-menu-item {
  min-height: 40px;
  height: fit-content;
  max-height: 80px;
}

.general-picker .current-menu-item > div {
  position: relative;
  width: calc(100% - 20px);
}

.general-picker .current-menu-item p {
  margin: 8px 0 5px;
}

.general-picker .current-menu-item h6 {
  max-width: 100%;
  margin: 5px 0 12px;
  font-size: 11px;
  color: rgb(160, 160, 160);
  font-weight: 500;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.general-picker .current-menu-item > .small-icon {
  transform: rotate(-90deg);
}

.modal-container > .general-picker-menu.modal {
  padding-left: 10px;
  padding-right: 10px;
}

.general-picker-menu > .flex-container {
  padding: 0 10px;
  justify-content: space-between;
}

.general-picker-menu > .body {
  width: 100%;
  height: 90%;
  min-height: 250px;
  max-height: 50vh;
  overflow-y: auto;
  margin: 10px 0 20px;
}

.general-picker-menu > .controls {
  height: 50px;
  padding: 0 10px;
}

.general-picker-menu > .controls > .flex-search {
  height: 42px;
  width: 100%;
  margin-left: 0;
}

.general-picker-menu > .controls > .flex-search > input {
  margin-left: 0;
  width: 100%;
}

.general-picker-card {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.general-picker-card:not(.selected-card):hover {
  background-color: var(--ui-card-bg-color-2);
}

.general-picker-card.selected-card {
  background-color: var(--ui-card-bg-color-1);
  border: none;
}

.block {
  display: block;
}

.general-picker-card > .block {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 15px 0;
}

.general-picker-card > .block > h4 {
  font-size: 12px;
  font-weight: 500;
  color: rgb(180, 180, 180);
}

.general-picker-card > .block > p {
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: rgb(160, 160, 160);
}

.general-picker-card > .img-container {
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.general-picker-card > .img-container > img {
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 100%;
  background-color: var(--ui-card-bg-color-2);
  padding: 5px;
  border-radius: 3px;
}

.general-picker-card > .img-container > img.icon {
  width: 60%;
  height: 60%;
  padding: 15%;
}

.general-picker-card > input[type=checkbox] {
  position: absolute;
  right: 10px;
}

/*Faqs*/

#faq {
  padding: 80px 10px 10px;
}

#faq > h1 {
  padding: 0 20px;
  color: rgb(180, 180, 180);
  font-weight: 400;
}

#faq > .flex-search {
  position: relative;
  width: clamp(250px, 100% - 40px, 400px);
  height: 42px;
  margin: 20px auto 30px;
}

#faq > .flex-search > input {
  width: 100%;
  background-color: rgb(2, 20, 34);
}

.faq-section {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.faq-sub-section {
  position: relative;
  width: clamp(200px, 100%, 300px);
  height: 400px;
  padding: 10px;
}

.faq-sub-section > h3 {
  font-weight: 500;
  font-size: 12px;
  color: rgb(120, 120, 120);
  text-transform: uppercase;
  margin-bottom: 20px;
}

summary {
  cursor: pointer;
  list-style: none;
  transition: all 150ms ease;
}

summary::-webkit-details-marker {
  display: none;
  background: none;
}

.faq-box {
  position: relative;
  width: 100%;
  margin: 10px 0 15px;
  font-size: 13.5px;
}

.faq-box > p {
  margin: 10px 0 20px;
  color: rgb(160, 160, 160);
  cursor: text;
  user-select: text;
}

.faq-box > summary {
  color: rgb(200, 200, 200);
}

.faq-box > summary:hover {
  text-decoration: underline;
}

.faq-box .highlight {
  color: #eb9812;
  cursor: text;
  user-select: text;
}

.emphasis {
  color: var(--ui-accent-color-primary);
  transition: all ease 250ms;
  cursor: pointer;
}

.emphasis:hover {
  color: var(--ui-accent-color-secondary);
}

/*Utilities*/

.error-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-container > .error-frame {
  display: flex;
  align-items: center;
}

.error-frame > .icon {
  width: 20px;
  height: 30px;
}

.error-frame > p {
  color: rgb(180, 180, 180);
  font-size: 13px;
  margin: 0 0 0 10px;
}

.modal-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(15, 15, 15, 0.65);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-container > .modal {
  position: relative;
  width: clamp(300px, 90%, 500px);
  height: fit-content;
  padding: 40px 15px 50px;
  background-color: var(--ui-panel-bg-color-1);
  transform: translateY(50%);
  opacity: 0;
  animation: rise 300ms ease 100ms forwards;
}

.modal-container > .modal > .plot-title > .icon {
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
}

.modal-container > .modal > .body {
  padding: 15px 0 25px;
  font-size: 13px;
}

.modal-container > .modal > .btn-group {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  border-top: rgb(40, 40, 40) 1px solid;
}

.modal-container > .modal > .btn-group button {
  max-width: fit-content;
  height: 34px;
  padding-left: 30px;
  padding-right: 30px;
}

/*form*/

form {
  position: relative;
}

form .input-container {
  margin: 15px 0;
}

form .btn-group {
  margin: 15px 0 0;
  padding: 15px 0 0;
}

form .flex-container .input-container:last-of-type,
.btn-group button:last-of-type {
  margin-left: 20px;
}

form .flex-container .input-container:only-of-type,
.btn-group button:only-of-type {
  margin-left: 0;
}

/*Input container*/

.input-container {
  position: relative;
  width: 100%;
}

.input-container > input {
  margin-top: 8px;
}

.input-container .icon {
  position: absolute;
  right: 12px;
  bottom: 9px;
  cursor: pointer;
}

/*Radio Group*/

.radio-group {
  margin: 10px 0;
}

.radio-group > .flex-container {
  margin-top: 10px;
  flex-wrap: wrap;
}

.radio-group > .flex-container > .flex-container {
  width: fit-content;
  max-width: 75px;
  padding: 0 20px 0 0;
  margin-bottom: 10px;
}

.horizontal-sep {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.horizontal-sep > p {
  color: rgb(160, 160, 160);
  width: 40px;
  font-size: 13px;
  text-align: center;
}

.horizontal-sep::before, .horizontal-sep::after {
  content: "";
  border-bottom: rgb(160, 160, 160) 1px solid;
  flex: 1 1 auto;
}

.btn-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-group .btn-group {
  width: fit-content;
}

.btn-group button {
  flex: 1;
  max-width: calc(50% - 10px);
  padding: 0 clamp(10px, 6%, 25px);
}

.btn-group .fit-btn {
  max-width: max-content;
}

.context-menu-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.context-menu {
  position: absolute;
  background-color: var(--ui-panel-bg-color-2);
  border-radius: 4px;
  box-shadow: var(--ui-std-box-shadow-1);
  overflow: hidden;
}

.context-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px 15px;
  color: rgb(180, 180, 180);
  border-bottom: rgb(40, 40, 40) 1px solid;
  font-size: 13px;
  text-transform: capitalize;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: var(--ui-card-bg-color-1);
}

.context-menu-item:last-of-type {
  border-bottom-color: transparent;
}

.context-menu-item > .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.flat-hint {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: rgba(240, 240, 240, 0.144);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: rgb(220, 220, 220);
  vertical-align: middle;
}

.capsule-badge {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 4px 10px;
  border-radius: 2.5em;
  border: rgb(80, 80, 80) 1px solid;
  color: rgb(160, 160, 160);
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  transition: all 300ms ease;
}

.capsule-badge:hover {
  border: rgb(120, 120, 120) 1px solid;
  color: rgb(240, 240, 240);
}

.hint {
  position: relative;
  padding: 5px 10px;
  background-color: var(--ui-panel-bg-color-2);
  color: rgb(220, 220, 220);
  font-size: 11px;
}

.capitalize {
  text-transform: capitalize;
}

.help {
  display: inline-flex;
  position: relative;
  pointer-events: none;
  padding: 3px 3px 3px 0;
  align-items: center;
}

.help > .screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
  z-index: 10;
  cursor: pointer;
}

.help * {
  pointer-events: none;
}

.utility {
  width: fit-content;
  height: fit-content;
}

.prompt {
  padding: 0;
  margin-top: 10px;
  width: max-content;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  animation: appear 250ms ease forwards;
}

.prompt-body {
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--ui-panel-bg-color-2);
  font-size: 12px;
  color: rgb(240, 240, 240);
}

.prompt-tip {
  box-sizing: content-box;
  position: absolute;
  top: calc(100% - 2px);
  left: calc(50% - 7px);
  width: 0;
  height: 0;
  border: 7px solid;
  border-color: var(--ui-panel-bg-color-2) transparent transparent transparent;
}

.modal {
  position: relative;
  padding: 50px 15px 15px;
  background-color: var(--ui-panel-bg-color-1);
  backdrop-filter: blur(20px);
  box-shadow: var(--ui-std-box-shadow-1);
  color: rgb(180, 180, 180);
  font-size: 12px;
}

.loader-frame {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-frame.inline {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  height: 100%;
  margin: 0 10px;
}

.loader-case {
  position: relative;
  width: clamp(40px, 10em, 50px);
  height: clamp(40px, 10em, 50px);
}

.loader-case-small {
  position: relative;
  width: clamp(25px, 10em, 35px);
  height: clamp(25px, 10em, 35px);
}

.loader-case-tiny {
  position: relative;
  width: 15px;
  height: 15px;
}

.loader {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: rgba(45, 45, 45, 0.5) 3px solid;
  border-top: rgb(180, 180, 180) 3px solid;
  animation: spin 1.2s linear infinite;
  z-index: 10;
}

.loader-text {
  margin-top: 10px;
  padding: 15px;
  width: clamp(200px, 250px, 100%);
  color: rgb(180, 180, 180);
  font-size: 13px;
  text-transform: capitalize;
  text-align: center;
}

.color-menu {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.color-menu-swatch {
  width: 20px;
  height: 20px;
  border: transparent 2px solid;
  cursor: pointer;
  transition-duration: 150ms;
  transition-timing-function: ease;
}

.selected-swatch {
  border: var(--ui-active-color) 2px solid;
}

.bubble {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  box-shadow: none;
  height: 15px;
  min-width: 15px;
  max-width: max-content;
  border-radius: 7.5px;
  padding: 0 4px 2px;
  background-color: rgb(224, 28, 28);
  color: rgb(220, 220, 220);
  font-size: 9px;
  font-weight: 700;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-indicator {
  position: relative;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
}

.body-step {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.rise {
  animation: riseMargin 300ms ease-in 50ms forwards;
}

.biscuit {
  position: absolute;
  bottom: 60px;
  left: 50%;
  width: clamp(60px, fit-content, 90%);
  transform: translateX(-50%);
  padding: 10px;
  margin-bottom: -50px;
  font-size: 13px;
  color: rgb(220, 220, 220);
  background-color: rgb(2, 12, 22);
  border-left: rgb(21, 102, 224) 5px solid;
  border-radius: 5px;
  overflow: hidden;
  opacity: 0;
}

.biscuit.error {
  border-left-color: rgb(224, 28, 28);
}

.biscuit.confirmation {
  border-left-color: rgb(17, 182, 17);
}

.biscuit > .icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.biscuit > p {
  width: max-content;
  margin-right: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.filter-dark {
  filter: brightness(50%);
}

.color-bar {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.color-bar-child {
  position: relative;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.color-bar-child > .swab {
  width: 100%;
  height: clamp(10px, 20%, 30px);
}

.color-bar-child > .swab-title {
  width: 100%;
  height: 80%;
  font-size: 10.5px;
  color: rgb(160, 160, 160);
  text-align: center;
}

.palette-picker {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.palette-picker .menu-item {
  padding: 10px 10px 0;
  background-color: var(--ui-panel-bg-color-2);
}

.palette-picker > .current-menu-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: transparent;
}

.palette-picker .color-bar {
  width: calc(100% - 20px);
  height: 20px;
}

.palette-picker .color-bar-child {
  height: 100%;
}

.palette-picker > .drop-down {
  width: calc(100% - 20px);
  left: 10px;
  top: 20px;
  overflow: hidden;
  transition: height 200ms ease;
}

.palette-picker > .drop-down > .menu-item:hover {
  width: 100%;
  background-color: var(--ui-card-bg-color-3);
}

.palette-picker-open > .current-menu-item > .small-icon {
  transform: rotate(180deg);
  transition: all ease 200ms;
}

/*Viz classes*/

.crosshair, 
.hint {
  width: fit-content;
  height: fit-content;
  background-color: var(--ui-panel-bg-color-1);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: var(--ui-std-box-shadow-1);
  padding-bottom: 1px;
}

.crosshair-title,
.crosshair-body > .flex-container {
  white-space: nowrap;
}

h4.crosshair-title {
  margin: 0 0 4px;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--ui-card-bg-color-1);
  color: rgb(180, 180, 180);
}

.hint {
  padding: 0;
}

.hint > .crosshair-body {
  padding: 4px 4px 8px;
}

.crosshair-body > .flex-container {
  position: relative;
  height: 22px;
  padding-right: 5px;
}

.crosshair-body > .flex-container:not(:last-of-type) {
  margin-bottom: 4px;
}

.crosshair-body > .flex-container > .color-rect {
  height: 100%;
  width: 5px;
  min-width: 5px;
}

.crosshair-body > .flex-container > p {
  text-transform: capitalize;
  color: rgb(160, 160, 160);
  line-height: 20px;
  font-size: 12px;
}

.crosshair-item-title {
  margin: 0 4px 0 8px;
}

.crosshair-value {
  margin: 0 8px 0 4px;
  font-weight: 500;
}

/*Plot classes*/

.scene {
  transform: scale(1, -1);
  touch-action: none;
}

.label-text {
  fill: #fafafa;
}

.bay-svg {
  cursor: pointer;
}

/*Responsive MQ*/

@media only screen and (max-width: 1000px) {

  .picker-menu {
    max-width: 330px;
  }

  header .tab-menu {
    display: none;
  }

  .hamburger {
    display: block;
  }

  #overview {
    display: block;
    padding: 60px 0 0;
  }

  #overview .map-container {
    width: 100%;
    height: calc(100% - 40px);
  }

  #composition {
    height: 33%;
    width: 100%;
  }

  #overview > .side-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 60px;
    z-index: 5;
  }

  #overview > .side-menu > .plot-title {
    cursor: pointer;
  }

  #overview > .side-menu > .plot-title > h4 > .small-icon {
    opacity: 1;
  }

  #overview > .side-menu.bottom-collapse {
    top: calc(100% - 40px);
    padding: 0;
    transition: all 800ms ease;
  }

  #overview > .side-menu > .btn-group {
    opacity: 0;
    animation: appear 300ms ease-in 700ms forwards;
  }

  #overview > .side-menu > .body {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  #overview > .side-menu.bottom-collapse > .body,
  #overview > .side-menu.bottom-collapse > .btn-group,
  #overview > .side-menu.bottom-collapse > .select,
  #overview > .side-menu.bottom-collapse > .option-area,
  #overview > .side-menu.bottom-collapse > .plot-title > .map-menu {
    display: none;
  }

  .side-menu.bottom-collapse > .plot-title > h4 > .small-icon {
    transform: rotate(180deg);
    opacity: 1;
  }

  .asset-card {
    max-width: 320px;
    margin: 0 10px 10px 0;
  }

  #asset {
    display: block;
  }

  #asset > .left-menu {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    height: fit-content;
  }
  
  #asset > .contents {
    position: relative;
    min-height: 60vh;
    padding: 0 10px 30px;
    display: block;
  }

  .contents > .congestion-plot-container {
    position: relative;
    width: 100%;
    max-width: unset;
    height: 440px;
    padding: 90px 15px 15px;
  }

  .contents > .stats {
    position: relative;
    width: 100%;
    height: 400px;
    padding: 50px 15px 15px;
    margin-top: 10px;
  }

  .congestion-plot-container > .congestion-plot {
    width: 100%;
    height: 80%;
  }

  .congestion-plot-container > .legend {
    height: 40px;
    width: 100%;
    display: flex;
    padding-left: 20px;
    overflow-x: auto;
  }

  .contents > .window-slider-frame {
    position: relative;
    width: 100%;
    height: 120px;
  }

  .contents > .station-map {
    position: relative;
    height: 80vh;
  }

  .contents > .utilization {
    display: inline-block;
    position: relative;
    padding: 40px 0 0;
    overflow-y: auto;
    width: 49%;
  }

  .contents > .vehicle-data {
    display: inline-block;
    width: 49%;
    margin-top: 10px;
    margin-left: 10px;
  }

  .contents > .metric {
    margin: 0 0 10px;
  }

  .contents > .plot {
    position: relative;
    width: 100%;
    height: 320px;
    max-width: unset;
  }
  
  #asset > .sub-menu {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  #profile form {
    max-width: none;
  }

  #profile .block {
    width: 100%;
  }

  #profile > .block-center {
    display: block;
    padding: 0;
  }

  #profile .vertical-menu {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: calc(100% - 20px);
    max-width: none;
    margin: 0 auto 30px;
  }

  #profile form #image-upload-container {
    width: 100%;
    margin: 0;
  }

  #profile form #image-upload-container > .image-upload {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 700px) {
  header > .flex-search {
    display: none;
  }

  #add-station {
    display: block;
    padding: 60px 0 0;
  }

  #overview .map-container {
    height: calc(100% - 40px);
    width: 100%;
  }

  #composition {
    display: none;
  }

  .sub-menu-item > h4 {
    display: none;
  }

  .asset-card {
    width: 100%;
    height: 75px;
    min-height: 75px;
    max-width: none;
    margin: 0 0 10px 0;
  }

  .station-card {
    height: fit-content;
  }

  .contents > .utilization {
    display: block;
    width: 100%;
  }

  .contents > .vehicle-data {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .signup-form > .flex-container {
    flex-direction: column;
  }

  .signup-form > .flex-container > .image-upload {
    width: 90px;
    height: 90px;
  }

  .signup-form > .flex-container > .image-upload > .profile-roundel {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
  }

  .flat-hint {
    display: none;
  }

  #add-station {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  #add-station > .form-progress > .form-step:not(.form-step-active) {
    display: none;
  }

  #add-station > #basic-details {
    width: 100%;
    height: fit-content;
    margin: 10px 0;
  }

  #add-station > .btn-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  #add-station > #add-bays, #add-station > .minimap-container, #add-station > .plot-editor {
    width: 100%;
    height: 100%;
    margin: 10px 0;
  }

  #add-station > .map-container {
    width: 100%;
    height: 100%;
    margin: 10px 0;
  }

  #overview > .side-menu > .body {
    flex-direction: column;
    flex-wrap: unset;
  }

  .picker-menu-container {
    background-color: rgba(15, 15, 15, 0.65);
    z-index: 100;
  }

  .picker-menu > .body > .vertical-menu {
    display: none;
  }

  .picker-menu .crumb-menu {
    display: unset;
  }
}

@media only screen and (max-width: 500px) {
  footer > .motto,
  .no-mobile {
    display: none;
  }
}